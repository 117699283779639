import Layout from '@/components/layouts/Layout'
import { Directors } from '@/components/pages/team/Directors'
import { GlobalTeam } from '@/components/pages/team/GlobalTeam'
import { Container, ContainerFullWidth } from '@/components/styled/Container'
import { H1 } from '@/components/styled/Typography'
import styled from '@emotion/styled'
import React from 'react'

const Heading = styled(H1)``

export default function Team(): JSX.Element {
  return (
    <Layout LayoutContainer={ContainerFullWidth}>
      <Heading>メンバー</Heading>
      <Container>
        <Directors />
      </Container>
      <GlobalTeam />
    </Layout>
  )
}
