import teamPlaceholder from '@/assets/images/team/team.png'
import TwoColumnLayout from '@/components/pages/home/TwoColumnLayout'
import { Container } from '@/components/styled/Container'
import { H2 } from '@/components/styled/Typography'
import { breakpoint } from '@/utils/styles'
import styled from '@emotion/styled'
import React from 'react'

const Heading = styled(H2)`
  color: #020247;
  margin-bottom: 0;
`

const TeamTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TeamText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 3rem;
  color: #726f90;
  font-size: 20px;
  line-height: 32px;

  ${breakpoint} {
    align-items: flex-start;
    padding: 0;
    margin-bottom: 0;
    font-size: 24px;
    line-height: 36px;
  }

  ul {
    padding: 0;
  }
`

const ListElement = styled.div`
  padding: 1rem 0;
  line-height: 2.5rem;

  strong {
    color: #020247;
  }
`

export function GlobalTeam(): JSX.Element {
  return (
    <Container>
      <TwoColumnLayout imageSrc={teamPlaceholder} imageFirst>
        <TeamTextWrapper>
          <TeamText>
            <Heading>グローバルなチーム</Heading>
            <ul>
              <ListElement>
                正社員の80%が外国人のグローバルテックスタートアップ
              </ListElement>
              <ListElement>
                英語と日本語が飛び交うグローバルな職場環境
              </ListElement>
              <ListElement>
                <strong>メンバーの出身国</strong>
                ：日本、アメリカ、イギリス、カナダ、ニュージーランド、フィリピン、インドネシア、ブラジル、ウクライナ、イタリア、中国
              </ListElement>
              <ListElement>
                <strong>メンバーの出身会社</strong>
                ：株式会社リクルート、バークレイズ証券株式会社、ラクスル株式会社、全日本空輸株式会社、大阪府庁、合同会社DMM.com、株式会社東急百貨店、株式会社ドーナツ
              </ListElement>
            </ul>
          </TeamText>
        </TeamTextWrapper>
      </TwoColumnLayout>
    </Container>
  )
}
