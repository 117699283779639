import alexPicture from '@/assets/images/team/alex.png'
import ayumiPicture from '@/assets/images/team/ayumi.png'
import davidPicture from '@/assets/images/team/david.png'
import { H2, H3, H4 } from '@/components/styled/Typography'
import { bodyFont, breakpoint, headingsFont } from '@/utils/styles'
import styled from '@emotion/styled'
import React from 'react'

const Wrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-bottom: 4rem;

  ${breakpoint} {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 4rem;
  }
`

const Heading = styled(H2)`
  text-align: center;
  margin-top: 0;
  margin-bottom: 1.5rem;

  ${breakpoint} {
    margin-bottom: 2.5rem;
  }
`

const Director = styled.div`
  background-color: white;
  box-shadow: 2px 8px 10px rgba(239, 239, 250, 0.4);
  color: #020247;
`

const DirectorPicture = styled.img`
  width: 100%;
  height: auto;
`

const DirectorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
`

const DirectorName = styled(H3)`
  font-weight: bold;
  margin: 1rem 0 0.5rem 0;
  font-family: ${headingsFont};
`

const DirectorTitle = styled(H4)`
  margin: 0 0 1rem 0;
  text-align: center;
  font-family: ${headingsFont};
`

const DirectorBio = styled.p`
  margin-top: 0;
  font-size: 16px;
  line-height: 24px;
  color: #726f90;
  font-family: ${bodyFont};
`

export function Directors(): JSX.Element {
  return (
    <>
      <Heading>LEADERSHIP</Heading>
      <Wrapper>
        <Director>
          <DirectorPicture src={ayumiPicture} alt="" />
          <DirectorInfo>
            <DirectorName>近本あゆみ</DirectorName>
            <DirectorTitle>Founder, 代表取締役 CEO</DirectorTitle>
            <DirectorBio>
              早稲田大学卒業後、株式会社リクルートに入社。入社2年目から国内向け通販の新規事業にて企画を担当する。その後リクルートでのECの経験を活かし、日本のお菓子は海外の幅広い人に受け入れられると考え2015年にmovefast（現ICHIGO）を創業しサブスク型越境ECサービス「TokyoTreat」をローンチする。2児の母でもあるワーキングマザー。
            </DirectorBio>
          </DirectorInfo>
        </Director>
        <Director>
          <DirectorPicture src={davidPicture} alt="" />
          <DirectorInfo>
            <DirectorName>デビッドアシキン</DirectorName>
            <DirectorTitle>Founder, 代表取締役 CTO</DirectorTitle>
            <DirectorBio>
              アメリカの大手半導体設計開発メーカーQualcomm (San
              Diego社）にて、ソフトウェアエンジニアとして携帯電話のアプリケーションプラットフォーム開発を経て、Barclays
              Capitalへ入社。
              マーケット部門のエクイティトレーダーとして、日本株のトレーダーを務める。その後、コイニー株式会社（現ヘイ株式会社）でCTOとして決済サービスの開発に従事。Carnegie
              Mellon University M.S.（2010年）東京工業大学 B.S.（2008年）
            </DirectorBio>
          </DirectorInfo>
        </Director>
        <Director>
          <DirectorPicture src={alexPicture} alt="" />
          <DirectorInfo>
            <DirectorName>野村 アレックス哲也</DirectorName>
            <DirectorTitle>Corporate Strategy</DirectorTitle>
            <DirectorBio>
              南カリフォルニア大学(USC)を卒業後、デロイト トーマツ
              コンサルティングに入社し、国内外の企業に対して経営、IT、M&A等のコンサルティング業務を経験。その後、GREE株式会社にて経営戦略、海外事業の推進、更にはEC事業の立上げに携わる。2015年にはOnedrops株式会社のCoFounder兼COOとしてグローバル人材開発事業の立上げや運営に従事。2021年よりICHIGO株式会社に参画し、経営戦略からオペレーションまで幅広い領域を担当。
            </DirectorBio>
          </DirectorInfo>
        </Director>
      </Wrapper>
    </>
  )
}
